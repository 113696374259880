import * as React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import ArticleCard from "../components/ArticleCard"

const IndexPage = ({ data }) => {
  const posts = data.allMdx.nodes
  return (
    <>
      <Seo title="Home" />
      <div className="container">
        {/* Latest Articles Section */}
        <section className="w-full lg:w-1/2">
          <h2 className="mb-4 text-2xl">Latest Articles</h2>
          <ul className="list-none flex flex-col gap-4">
            {posts.map(post => {
              return <ArticleCard key={post.fields.slug} post={post} />
            })}
          </ul>
        </section>
      </div>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          updated(formatString: "MMMM DD, YYYY")
          title
          description
          category
          cover {
            childImageSharp {
              gatsbyImageData(
                quality: 60
                placeholder: BLURRED
                layout: CONSTRAINED
                width: 176
                height: 128
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
      }
    }
  }
`
