import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const ArticleCard = ({ post }) => {
  const title = post.frontmatter.title || post.fields.slug

  const isUpdated = post.frontmatter.date !== post.frontmatter.updated
  const { cover } = post.frontmatter

  return (
    <li>
      <article
        itemScope
        itemType="http://schema.org/Article"
        className="flex gap-2"
      >
        {/* Image */}
        <div className="w-32 h-24 md:w-44 md:h-32 flex-shrink-0">
          <Link to={`${post.fields.slug}`} itemProp="url">
            <GatsbyImage
              image={cover.childImageSharp.gatsbyImageData}
              alt={title}
              className="w-full h-full"
            />
          </Link>
        </div>

        {/* Text Section */}
        <div className="flex flex-col justify-between">
          <header>
            <h3>
              <Link to={`${post.fields.slug}`} itemProp="url">
                <span
                  itemProp="headline"
                  className="line-clamp-3 lg:line-clamp-2"
                >
                  {title}
                </span>
              </Link>
            </h3>
          </header>
          <p className="text-sm hidden md:block md:line-clamp-2">
            {post.frontmatter.description || post.excerpt}
          </p>
          {isUpdated ? (
            <p className="text-xs pb-2 md:pb-0">
              Updated {post.frontmatter.updated}
            </p>
          ) : (
            <p className="text-xs pb-2 md:pb-0">{post.frontmatter.date}</p>
          )}
        </div>
      </article>
    </li>
  )
}

export default ArticleCard
